/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LoginImport } from './routes/login'
import { Route as AuthImport } from './routes/_auth'
import { Route as AuthIndexImport } from './routes/_auth/index'
import { Route as AuthSettingsImport } from './routes/_auth/settings'
import { Route as AuthProjectsImport } from './routes/_auth/projects'
import { Route as AuthInboxImport } from './routes/_auth/inbox'
import { Route as AuthHolidaysImport } from './routes/_auth/holidays'
import { Route as AuthColorsImport } from './routes/_auth/colors'
import { Route as AuthActivityImport } from './routes/_auth/activity'
import { Route as AuthAdminAdminImport } from './routes/_auth/admin/_admin'
import { Route as AuthProjectProjectIdIndexImport } from './routes/_auth/project.$projectId/index'
import { Route as AuthAdminAdminIndexImport } from './routes/_auth/admin/_admin/index'
import { Route as AuthProjectProjectIdTimelineImport } from './routes/_auth/project.$projectId/timeline'
import { Route as AuthProjectProjectIdSettingsImport } from './routes/_auth/project.$projectId/settings'
import { Route as AuthProjectProjectIdListImport } from './routes/_auth/project.$projectId/list'
import { Route as AuthProjectProjectIdEpicsImport } from './routes/_auth/project.$projectId/epics'
import { Route as AuthProjectProjectIdCyclesImport } from './routes/_auth/project.$projectId/cycles'
import { Route as AuthAdminAdminUsersImport } from './routes/_auth/admin/_admin/users'
import { Route as AuthAdminAdminTimelineImport } from './routes/_auth/admin/_admin/timeline'
import { Route as AuthAdminAdminProjectsImport } from './routes/_auth/admin/_admin/projects'
import { Route as AuthProjectProjectIdIssueIssueIdImport } from './routes/_auth/project.$projectId/issue.$issueId'

// Create Virtual Routes

const AuthAdminImport = createFileRoute('/_auth/admin')()

// Create/Update Routes

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const AuthAdminRoute = AuthAdminImport.update({
  id: '/admin',
  path: '/admin',
  getParentRoute: () => AuthRoute,
} as any)

const AuthIndexRoute = AuthIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthSettingsRoute = AuthSettingsImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => AuthRoute,
} as any)

const AuthProjectsRoute = AuthProjectsImport.update({
  id: '/projects',
  path: '/projects',
  getParentRoute: () => AuthRoute,
} as any)

const AuthInboxRoute = AuthInboxImport.update({
  id: '/inbox',
  path: '/inbox',
  getParentRoute: () => AuthRoute,
} as any)

const AuthHolidaysRoute = AuthHolidaysImport.update({
  id: '/holidays',
  path: '/holidays',
  getParentRoute: () => AuthRoute,
} as any)

const AuthColorsRoute = AuthColorsImport.update({
  id: '/colors',
  path: '/colors',
  getParentRoute: () => AuthRoute,
} as any)

const AuthActivityRoute = AuthActivityImport.update({
  id: '/activity',
  path: '/activity',
  getParentRoute: () => AuthRoute,
} as any)

const AuthAdminAdminRoute = AuthAdminAdminImport.update({
  id: '/_admin',
  getParentRoute: () => AuthAdminRoute,
} as any)

const AuthProjectProjectIdIndexRoute = AuthProjectProjectIdIndexImport.update({
  id: '/project/$projectId/',
  path: '/project/$projectId/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthAdminAdminIndexRoute = AuthAdminAdminIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthAdminAdminRoute,
} as any)

const AuthProjectProjectIdTimelineRoute =
  AuthProjectProjectIdTimelineImport.update({
    id: '/project/$projectId/timeline',
    path: '/project/$projectId/timeline',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthProjectProjectIdSettingsRoute =
  AuthProjectProjectIdSettingsImport.update({
    id: '/project/$projectId/settings',
    path: '/project/$projectId/settings',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthProjectProjectIdListRoute = AuthProjectProjectIdListImport.update({
  id: '/project/$projectId/list',
  path: '/project/$projectId/list',
  getParentRoute: () => AuthRoute,
} as any)

const AuthProjectProjectIdEpicsRoute = AuthProjectProjectIdEpicsImport.update({
  id: '/project/$projectId/epics',
  path: '/project/$projectId/epics',
  getParentRoute: () => AuthRoute,
} as any)

const AuthProjectProjectIdCyclesRoute = AuthProjectProjectIdCyclesImport.update(
  {
    id: '/project/$projectId/cycles',
    path: '/project/$projectId/cycles',
    getParentRoute: () => AuthRoute,
  } as any,
)

const AuthAdminAdminUsersRoute = AuthAdminAdminUsersImport.update({
  id: '/users',
  path: '/users',
  getParentRoute: () => AuthAdminAdminRoute,
} as any)

const AuthAdminAdminTimelineRoute = AuthAdminAdminTimelineImport.update({
  id: '/timeline',
  path: '/timeline',
  getParentRoute: () => AuthAdminAdminRoute,
} as any)

const AuthAdminAdminProjectsRoute = AuthAdminAdminProjectsImport.update({
  id: '/projects',
  path: '/projects',
  getParentRoute: () => AuthAdminAdminRoute,
} as any)

const AuthProjectProjectIdIssueIssueIdRoute =
  AuthProjectProjectIdIssueIssueIdImport.update({
    id: '/project/$projectId/issue/$issueId',
    path: '/project/$projectId/issue/$issueId',
    getParentRoute: () => AuthRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/_auth/activity': {
      id: '/_auth/activity'
      path: '/activity'
      fullPath: '/activity'
      preLoaderRoute: typeof AuthActivityImport
      parentRoute: typeof AuthImport
    }
    '/_auth/colors': {
      id: '/_auth/colors'
      path: '/colors'
      fullPath: '/colors'
      preLoaderRoute: typeof AuthColorsImport
      parentRoute: typeof AuthImport
    }
    '/_auth/holidays': {
      id: '/_auth/holidays'
      path: '/holidays'
      fullPath: '/holidays'
      preLoaderRoute: typeof AuthHolidaysImport
      parentRoute: typeof AuthImport
    }
    '/_auth/inbox': {
      id: '/_auth/inbox'
      path: '/inbox'
      fullPath: '/inbox'
      preLoaderRoute: typeof AuthInboxImport
      parentRoute: typeof AuthImport
    }
    '/_auth/projects': {
      id: '/_auth/projects'
      path: '/projects'
      fullPath: '/projects'
      preLoaderRoute: typeof AuthProjectsImport
      parentRoute: typeof AuthImport
    }
    '/_auth/settings': {
      id: '/_auth/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof AuthSettingsImport
      parentRoute: typeof AuthImport
    }
    '/_auth/': {
      id: '/_auth/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/admin': {
      id: '/_auth/admin'
      path: '/admin'
      fullPath: '/admin'
      preLoaderRoute: typeof AuthAdminImport
      parentRoute: typeof AuthImport
    }
    '/_auth/admin/_admin': {
      id: '/_auth/admin/_admin'
      path: '/admin'
      fullPath: '/admin'
      preLoaderRoute: typeof AuthAdminAdminImport
      parentRoute: typeof AuthAdminRoute
    }
    '/_auth/admin/_admin/projects': {
      id: '/_auth/admin/_admin/projects'
      path: '/projects'
      fullPath: '/admin/projects'
      preLoaderRoute: typeof AuthAdminAdminProjectsImport
      parentRoute: typeof AuthAdminAdminImport
    }
    '/_auth/admin/_admin/timeline': {
      id: '/_auth/admin/_admin/timeline'
      path: '/timeline'
      fullPath: '/admin/timeline'
      preLoaderRoute: typeof AuthAdminAdminTimelineImport
      parentRoute: typeof AuthAdminAdminImport
    }
    '/_auth/admin/_admin/users': {
      id: '/_auth/admin/_admin/users'
      path: '/users'
      fullPath: '/admin/users'
      preLoaderRoute: typeof AuthAdminAdminUsersImport
      parentRoute: typeof AuthAdminAdminImport
    }
    '/_auth/project/$projectId/cycles': {
      id: '/_auth/project/$projectId/cycles'
      path: '/project/$projectId/cycles'
      fullPath: '/project/$projectId/cycles'
      preLoaderRoute: typeof AuthProjectProjectIdCyclesImport
      parentRoute: typeof AuthImport
    }
    '/_auth/project/$projectId/epics': {
      id: '/_auth/project/$projectId/epics'
      path: '/project/$projectId/epics'
      fullPath: '/project/$projectId/epics'
      preLoaderRoute: typeof AuthProjectProjectIdEpicsImport
      parentRoute: typeof AuthImport
    }
    '/_auth/project/$projectId/list': {
      id: '/_auth/project/$projectId/list'
      path: '/project/$projectId/list'
      fullPath: '/project/$projectId/list'
      preLoaderRoute: typeof AuthProjectProjectIdListImport
      parentRoute: typeof AuthImport
    }
    '/_auth/project/$projectId/settings': {
      id: '/_auth/project/$projectId/settings'
      path: '/project/$projectId/settings'
      fullPath: '/project/$projectId/settings'
      preLoaderRoute: typeof AuthProjectProjectIdSettingsImport
      parentRoute: typeof AuthImport
    }
    '/_auth/project/$projectId/timeline': {
      id: '/_auth/project/$projectId/timeline'
      path: '/project/$projectId/timeline'
      fullPath: '/project/$projectId/timeline'
      preLoaderRoute: typeof AuthProjectProjectIdTimelineImport
      parentRoute: typeof AuthImport
    }
    '/_auth/admin/_admin/': {
      id: '/_auth/admin/_admin/'
      path: '/'
      fullPath: '/admin/'
      preLoaderRoute: typeof AuthAdminAdminIndexImport
      parentRoute: typeof AuthAdminAdminImport
    }
    '/_auth/project/$projectId/': {
      id: '/_auth/project/$projectId/'
      path: '/project/$projectId'
      fullPath: '/project/$projectId'
      preLoaderRoute: typeof AuthProjectProjectIdIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/project/$projectId/issue/$issueId': {
      id: '/_auth/project/$projectId/issue/$issueId'
      path: '/project/$projectId/issue/$issueId'
      fullPath: '/project/$projectId/issue/$issueId'
      preLoaderRoute: typeof AuthProjectProjectIdIssueIssueIdImport
      parentRoute: typeof AuthImport
    }
  }
}

// Create and export the route tree

interface AuthAdminAdminRouteChildren {
  AuthAdminAdminProjectsRoute: typeof AuthAdminAdminProjectsRoute
  AuthAdminAdminTimelineRoute: typeof AuthAdminAdminTimelineRoute
  AuthAdminAdminUsersRoute: typeof AuthAdminAdminUsersRoute
  AuthAdminAdminIndexRoute: typeof AuthAdminAdminIndexRoute
}

const AuthAdminAdminRouteChildren: AuthAdminAdminRouteChildren = {
  AuthAdminAdminProjectsRoute: AuthAdminAdminProjectsRoute,
  AuthAdminAdminTimelineRoute: AuthAdminAdminTimelineRoute,
  AuthAdminAdminUsersRoute: AuthAdminAdminUsersRoute,
  AuthAdminAdminIndexRoute: AuthAdminAdminIndexRoute,
}

const AuthAdminAdminRouteWithChildren = AuthAdminAdminRoute._addFileChildren(
  AuthAdminAdminRouteChildren,
)

interface AuthAdminRouteChildren {
  AuthAdminAdminRoute: typeof AuthAdminAdminRouteWithChildren
}

const AuthAdminRouteChildren: AuthAdminRouteChildren = {
  AuthAdminAdminRoute: AuthAdminAdminRouteWithChildren,
}

const AuthAdminRouteWithChildren = AuthAdminRoute._addFileChildren(
  AuthAdminRouteChildren,
)

interface AuthRouteChildren {
  AuthActivityRoute: typeof AuthActivityRoute
  AuthColorsRoute: typeof AuthColorsRoute
  AuthHolidaysRoute: typeof AuthHolidaysRoute
  AuthInboxRoute: typeof AuthInboxRoute
  AuthProjectsRoute: typeof AuthProjectsRoute
  AuthSettingsRoute: typeof AuthSettingsRoute
  AuthIndexRoute: typeof AuthIndexRoute
  AuthAdminRoute: typeof AuthAdminRouteWithChildren
  AuthProjectProjectIdCyclesRoute: typeof AuthProjectProjectIdCyclesRoute
  AuthProjectProjectIdEpicsRoute: typeof AuthProjectProjectIdEpicsRoute
  AuthProjectProjectIdListRoute: typeof AuthProjectProjectIdListRoute
  AuthProjectProjectIdSettingsRoute: typeof AuthProjectProjectIdSettingsRoute
  AuthProjectProjectIdTimelineRoute: typeof AuthProjectProjectIdTimelineRoute
  AuthProjectProjectIdIndexRoute: typeof AuthProjectProjectIdIndexRoute
  AuthProjectProjectIdIssueIssueIdRoute: typeof AuthProjectProjectIdIssueIssueIdRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthActivityRoute: AuthActivityRoute,
  AuthColorsRoute: AuthColorsRoute,
  AuthHolidaysRoute: AuthHolidaysRoute,
  AuthInboxRoute: AuthInboxRoute,
  AuthProjectsRoute: AuthProjectsRoute,
  AuthSettingsRoute: AuthSettingsRoute,
  AuthIndexRoute: AuthIndexRoute,
  AuthAdminRoute: AuthAdminRouteWithChildren,
  AuthProjectProjectIdCyclesRoute: AuthProjectProjectIdCyclesRoute,
  AuthProjectProjectIdEpicsRoute: AuthProjectProjectIdEpicsRoute,
  AuthProjectProjectIdListRoute: AuthProjectProjectIdListRoute,
  AuthProjectProjectIdSettingsRoute: AuthProjectProjectIdSettingsRoute,
  AuthProjectProjectIdTimelineRoute: AuthProjectProjectIdTimelineRoute,
  AuthProjectProjectIdIndexRoute: AuthProjectProjectIdIndexRoute,
  AuthProjectProjectIdIssueIssueIdRoute: AuthProjectProjectIdIssueIssueIdRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof AuthRouteWithChildren
  '/login': typeof LoginRoute
  '/activity': typeof AuthActivityRoute
  '/colors': typeof AuthColorsRoute
  '/holidays': typeof AuthHolidaysRoute
  '/inbox': typeof AuthInboxRoute
  '/projects': typeof AuthProjectsRoute
  '/settings': typeof AuthSettingsRoute
  '/': typeof AuthIndexRoute
  '/admin': typeof AuthAdminAdminRouteWithChildren
  '/admin/projects': typeof AuthAdminAdminProjectsRoute
  '/admin/timeline': typeof AuthAdminAdminTimelineRoute
  '/admin/users': typeof AuthAdminAdminUsersRoute
  '/project/$projectId/cycles': typeof AuthProjectProjectIdCyclesRoute
  '/project/$projectId/epics': typeof AuthProjectProjectIdEpicsRoute
  '/project/$projectId/list': typeof AuthProjectProjectIdListRoute
  '/project/$projectId/settings': typeof AuthProjectProjectIdSettingsRoute
  '/project/$projectId/timeline': typeof AuthProjectProjectIdTimelineRoute
  '/admin/': typeof AuthAdminAdminIndexRoute
  '/project/$projectId': typeof AuthProjectProjectIdIndexRoute
  '/project/$projectId/issue/$issueId': typeof AuthProjectProjectIdIssueIssueIdRoute
}

export interface FileRoutesByTo {
  '/login': typeof LoginRoute
  '/activity': typeof AuthActivityRoute
  '/colors': typeof AuthColorsRoute
  '/holidays': typeof AuthHolidaysRoute
  '/inbox': typeof AuthInboxRoute
  '/projects': typeof AuthProjectsRoute
  '/settings': typeof AuthSettingsRoute
  '/': typeof AuthIndexRoute
  '/admin': typeof AuthAdminAdminIndexRoute
  '/admin/projects': typeof AuthAdminAdminProjectsRoute
  '/admin/timeline': typeof AuthAdminAdminTimelineRoute
  '/admin/users': typeof AuthAdminAdminUsersRoute
  '/project/$projectId/cycles': typeof AuthProjectProjectIdCyclesRoute
  '/project/$projectId/epics': typeof AuthProjectProjectIdEpicsRoute
  '/project/$projectId/list': typeof AuthProjectProjectIdListRoute
  '/project/$projectId/settings': typeof AuthProjectProjectIdSettingsRoute
  '/project/$projectId/timeline': typeof AuthProjectProjectIdTimelineRoute
  '/project/$projectId': typeof AuthProjectProjectIdIndexRoute
  '/project/$projectId/issue/$issueId': typeof AuthProjectProjectIdIssueIssueIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_auth': typeof AuthRouteWithChildren
  '/login': typeof LoginRoute
  '/_auth/activity': typeof AuthActivityRoute
  '/_auth/colors': typeof AuthColorsRoute
  '/_auth/holidays': typeof AuthHolidaysRoute
  '/_auth/inbox': typeof AuthInboxRoute
  '/_auth/projects': typeof AuthProjectsRoute
  '/_auth/settings': typeof AuthSettingsRoute
  '/_auth/': typeof AuthIndexRoute
  '/_auth/admin': typeof AuthAdminRouteWithChildren
  '/_auth/admin/_admin': typeof AuthAdminAdminRouteWithChildren
  '/_auth/admin/_admin/projects': typeof AuthAdminAdminProjectsRoute
  '/_auth/admin/_admin/timeline': typeof AuthAdminAdminTimelineRoute
  '/_auth/admin/_admin/users': typeof AuthAdminAdminUsersRoute
  '/_auth/project/$projectId/cycles': typeof AuthProjectProjectIdCyclesRoute
  '/_auth/project/$projectId/epics': typeof AuthProjectProjectIdEpicsRoute
  '/_auth/project/$projectId/list': typeof AuthProjectProjectIdListRoute
  '/_auth/project/$projectId/settings': typeof AuthProjectProjectIdSettingsRoute
  '/_auth/project/$projectId/timeline': typeof AuthProjectProjectIdTimelineRoute
  '/_auth/admin/_admin/': typeof AuthAdminAdminIndexRoute
  '/_auth/project/$projectId/': typeof AuthProjectProjectIdIndexRoute
  '/_auth/project/$projectId/issue/$issueId': typeof AuthProjectProjectIdIssueIssueIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/login'
    | '/activity'
    | '/colors'
    | '/holidays'
    | '/inbox'
    | '/projects'
    | '/settings'
    | '/'
    | '/admin'
    | '/admin/projects'
    | '/admin/timeline'
    | '/admin/users'
    | '/project/$projectId/cycles'
    | '/project/$projectId/epics'
    | '/project/$projectId/list'
    | '/project/$projectId/settings'
    | '/project/$projectId/timeline'
    | '/admin/'
    | '/project/$projectId'
    | '/project/$projectId/issue/$issueId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/login'
    | '/activity'
    | '/colors'
    | '/holidays'
    | '/inbox'
    | '/projects'
    | '/settings'
    | '/'
    | '/admin'
    | '/admin/projects'
    | '/admin/timeline'
    | '/admin/users'
    | '/project/$projectId/cycles'
    | '/project/$projectId/epics'
    | '/project/$projectId/list'
    | '/project/$projectId/settings'
    | '/project/$projectId/timeline'
    | '/project/$projectId'
    | '/project/$projectId/issue/$issueId'
  id:
    | '__root__'
    | '/_auth'
    | '/login'
    | '/_auth/activity'
    | '/_auth/colors'
    | '/_auth/holidays'
    | '/_auth/inbox'
    | '/_auth/projects'
    | '/_auth/settings'
    | '/_auth/'
    | '/_auth/admin'
    | '/_auth/admin/_admin'
    | '/_auth/admin/_admin/projects'
    | '/_auth/admin/_admin/timeline'
    | '/_auth/admin/_admin/users'
    | '/_auth/project/$projectId/cycles'
    | '/_auth/project/$projectId/epics'
    | '/_auth/project/$projectId/list'
    | '/_auth/project/$projectId/settings'
    | '/_auth/project/$projectId/timeline'
    | '/_auth/admin/_admin/'
    | '/_auth/project/$projectId/'
    | '/_auth/project/$projectId/issue/$issueId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthRoute: typeof AuthRouteWithChildren
  LoginRoute: typeof LoginRoute
}

const rootRouteChildren: RootRouteChildren = {
  AuthRoute: AuthRouteWithChildren,
  LoginRoute: LoginRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_auth",
        "/login"
      ]
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/activity",
        "/_auth/colors",
        "/_auth/holidays",
        "/_auth/inbox",
        "/_auth/projects",
        "/_auth/settings",
        "/_auth/",
        "/_auth/admin",
        "/_auth/project/$projectId/cycles",
        "/_auth/project/$projectId/epics",
        "/_auth/project/$projectId/list",
        "/_auth/project/$projectId/settings",
        "/_auth/project/$projectId/timeline",
        "/_auth/project/$projectId/",
        "/_auth/project/$projectId/issue/$issueId"
      ]
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/_auth/activity": {
      "filePath": "_auth/activity.tsx",
      "parent": "/_auth"
    },
    "/_auth/colors": {
      "filePath": "_auth/colors.tsx",
      "parent": "/_auth"
    },
    "/_auth/holidays": {
      "filePath": "_auth/holidays.tsx",
      "parent": "/_auth"
    },
    "/_auth/inbox": {
      "filePath": "_auth/inbox.tsx",
      "parent": "/_auth"
    },
    "/_auth/projects": {
      "filePath": "_auth/projects.tsx",
      "parent": "/_auth"
    },
    "/_auth/settings": {
      "filePath": "_auth/settings.tsx",
      "parent": "/_auth"
    },
    "/_auth/": {
      "filePath": "_auth/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/admin": {
      "filePath": "_auth/admin",
      "parent": "/_auth",
      "children": [
        "/_auth/admin/_admin"
      ]
    },
    "/_auth/admin/_admin": {
      "filePath": "_auth/admin/_admin.tsx",
      "parent": "/_auth/admin",
      "children": [
        "/_auth/admin/_admin/projects",
        "/_auth/admin/_admin/timeline",
        "/_auth/admin/_admin/users",
        "/_auth/admin/_admin/"
      ]
    },
    "/_auth/admin/_admin/projects": {
      "filePath": "_auth/admin/_admin/projects.tsx",
      "parent": "/_auth/admin/_admin"
    },
    "/_auth/admin/_admin/timeline": {
      "filePath": "_auth/admin/_admin/timeline.tsx",
      "parent": "/_auth/admin/_admin"
    },
    "/_auth/admin/_admin/users": {
      "filePath": "_auth/admin/_admin/users.tsx",
      "parent": "/_auth/admin/_admin"
    },
    "/_auth/project/$projectId/cycles": {
      "filePath": "_auth/project.$projectId/cycles.tsx",
      "parent": "/_auth"
    },
    "/_auth/project/$projectId/epics": {
      "filePath": "_auth/project.$projectId/epics.tsx",
      "parent": "/_auth"
    },
    "/_auth/project/$projectId/list": {
      "filePath": "_auth/project.$projectId/list.tsx",
      "parent": "/_auth"
    },
    "/_auth/project/$projectId/settings": {
      "filePath": "_auth/project.$projectId/settings.tsx",
      "parent": "/_auth"
    },
    "/_auth/project/$projectId/timeline": {
      "filePath": "_auth/project.$projectId/timeline.tsx",
      "parent": "/_auth"
    },
    "/_auth/admin/_admin/": {
      "filePath": "_auth/admin/_admin/index.tsx",
      "parent": "/_auth/admin/_admin"
    },
    "/_auth/project/$projectId/": {
      "filePath": "_auth/project.$projectId/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/project/$projectId/issue/$issueId": {
      "filePath": "_auth/project.$projectId/issue.$issueId.tsx",
      "parent": "/_auth"
    }
  }
}
ROUTE_MANIFEST_END */
