import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    Transition,
} from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/solid'
import {
    ActivityLogIcon,
    CheckCircledIcon,
    CopyIcon,
    PlayIcon,
    StopIcon,
} from '@radix-ui/react-icons'
import { BoardIssue } from '@schema'
import { Link } from '@tanstack/react-router'
import { cva } from 'class-variance-authority'
import { CalendarRange } from 'lucide-react'
import { Fragment, useEffect, useState } from 'react'

import { useProject } from '../../contexts/project.context'
import { copyToClipboard } from '../../utils/clipboard'
import { cn } from '../../utils/cn'
import { formatDuration } from '../../utils/date'
import { trpc } from '../../utils/trpc'
import { AvatarIcon } from '../Icons/AvatarIcon'
import { Avatar, AvatarImage } from '../Primitives/Avatar'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../Primitives/Tooltip'
import { CountDown } from './CountDown'

export const Card = ({ issue, isOverlay = false }: { issue: BoardIssue; isOverlay: boolean }) => {
    const { project } = useProject()

    const [hasCopied, setHasCopied] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setHasCopied(false)
        }, 2000)
    }, [hasCopied])

    const { data } = trpc.tracker.getOngoingEntry.useQuery(undefined)

    const { mutate } = trpc.tracker.start.useMutation({
        onSuccess: async () => {
            await context.tracker.invalidate()
        },
    })

    const { mutate: mutateStop } = trpc.tracker.stop.useMutation({
        onSuccess: async () => {
            await context.tracker.invalidate()
        },
    })

    const { data: trackedSum } = trpc.tracker.getTimeEntryByIssue.useQuery({
        issueId: issue.id,
    })

    const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
        id: issue.id,
        data: issue,
    })

    const context = trpc.useUtils()
    const { mutate: editAssigneeMutation } = trpc.issue.editProperties.useMutation({
        onSuccess: () => {
            context.project.getForBoard.invalidate({ id: project.id })
        },
    })

    const style = {
        transition,
        transform: CSS.Translate.toString(transform),
    }

    const handleStartTime = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation()
        e.preventDefault()
        mutate({ issueId: issue.id })
    }

    const handleStopTime = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation()
        e.preventDefault()
        mutateStop()
    }

    const handleAddAssignee = async (assignees: string[]) => {
        editAssigneeMutation({ issueId: issue.id, assignees })
    }

    const variants = cva('', {
        variants: {
            dragging: {
                over: 'opacity-30 ring-2',
                overlay: 'ring-1 ring-primary',
            },
        },
    })

    return (
        <div
            {...listeners}
            {...attributes}
            ref={setNodeRef}
            className={cn(
                variants({ dragging: isOverlay ? 'overlay' : isDragging ? 'over' : undefined }),
                'group/card w-full rounded border-[.1px] border-muted-foreground bg-secondary p-2 text-primary dark:bg-gradient-to-tl dark:from-muted/20 dark:to-secondary',
            )}
            style={style}
        >
            <Link
                to={`/project/$projectId/issue/$issueId`}
                params={{ projectId: issue.projectId, issueId: issue.id }}
            >
                <div
                    className="mb-2 flex items-center"
                    onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                    }}
                >
                    <TooltipProvider>
                        <Tooltip open={hasCopied} delayDuration={0}>
                            <TooltipTrigger>
                                <div
                                    onClick={() => {
                                        copyToClipboard(`#${project?.id}-${issue.key}`)
                                        setHasCopied(true)
                                    }}
                                    className=" flex items-center text-muted-foreground"
                                >
                                    <CopyIcon className="w-4" />
                                    <span className="ml-1 text-xs font-bold">
                                        {project?.id}-{issue.key}
                                    </span>
                                </div>
                            </TooltipTrigger>
                            <TooltipContent>
                                {hasCopied ? 'Copied' : 'Copy'} #{project?.id}-{issue.key}
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                    {issue.completedAt && <CheckCircledIcon className="ml-1 text-green-400" />}
                </div>
                <h2 className="mb-2 text-sm text-primary">{issue.name}</h2>
                <div
                    onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                    }}
                >
                    <Listbox
                        value={issue.assignees.map((a) => a.id)}
                        multiple
                        onChange={handleAddAssignee}
                    >
                        {({ open }) => (
                            <div className="relative">
                                <ListboxButton className="mb-2 flex items-center rounded-md bg-transparent text-sm ">
                                    {issue.assignees.length > 0 ? (
                                        issue.assignees.map((people) => (
                                            <span key={people.id} className="flex items-center">
                                                <Avatar className="size-5">
                                                    <AvatarImage src={people.avatar} />
                                                </Avatar>
                                            </span>
                                        ))
                                    ) : (
                                        <Avatar className="flex size-5 items-center justify-center bg-primary-foreground">
                                            <AvatarIcon className="size-5" />
                                        </Avatar>
                                    )}
                                </ListboxButton>

                                <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <ListboxOptions className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md border bg-muted py-1 text-sm text-popover-foreground shadow-md">
                                        {project.members.map((person) => (
                                            <ListboxOption
                                                key={person.id}
                                                className={({ focus }) =>
                                                    cn(
                                                        'relative flex w-full cursor-default select-none items-center px-4 py-2',
                                                        focus ? 'bg-primary text-secondary' : '',
                                                    )
                                                }
                                                value={person.id}
                                            >
                                                {({ selected, focus }) => (
                                                    <>
                                                        <div className="flex items-center">
                                                            <img
                                                                src={person.avatar}
                                                                alt=""
                                                                className="size-5 shrink-0 rounded-full"
                                                            />
                                                            <span
                                                                className={cn(
                                                                    selected
                                                                        ? 'font-semibold'
                                                                        : 'font-normal',
                                                                    'ml-3 block truncate',
                                                                )}
                                                            >
                                                                {person.name}
                                                            </span>
                                                        </div>

                                                        {selected ? (
                                                            <span
                                                                className={cn(
                                                                    focus
                                                                        ? 'text-secondary'
                                                                        : 'text-primary',
                                                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                )}
                                                            >
                                                                <CheckIcon
                                                                    className="size-5"
                                                                    aria-hidden="true"
                                                                />
                                                            </span>
                                                        ) : null}
                                                    </>
                                                )}
                                            </ListboxOption>
                                        ))}
                                    </ListboxOptions>
                                </Transition>
                            </div>
                        )}
                    </Listbox>
                </div>
                {issue.epic && (
                    <div className="mb-2 flex items-center justify-start text-violet-500">
                        <ActivityLogIcon className="size-3 " />
                        <span className="ml-1 text-xs font-light">{issue.epic.name}</span>
                    </div>
                )}
                {issue.cycle && (
                    <div className="mb-2 flex items-center justify-start text-red-400">
                        <CalendarRange className="size-3 " />
                        <span className="ml-1 text-xs font-light">{issue.cycle.name}</span>
                    </div>
                )}
                <div
                    className={cn(
                        'flex items-center justify-between text-sm text-primary',
                        !trackedSum?._sum.timeSpent && 'justify-end',
                    )}
                >
                    {trackedSum?._sum.timeSpent && (
                        <div className="rounded-md bg-primary px-2 py-1 text-xs text-muted">
                            {formatDuration(trackedSum?._sum.timeSpent)}
                        </div>
                    )}

                    {data?.issueId === issue.id ? (
                        <div className="flex space-x-2">
                            <div
                                onClick={handleStopTime}
                                className="flex size-5 items-center justify-center rounded-full bg-red-700 text-white"
                            >
                                <StopIcon className="h-3" />
                            </div>
                            <CountDown startTime={data.startDate} />
                        </div>
                    ) : (
                        <div
                            onClick={handleStartTime}
                            className="flex size-5 items-center justify-center rounded-full bg-green-700 text-white"
                        >
                            <PlayIcon className="h-3" />
                        </div>
                    )}
                </div>
            </Link>
        </div>
    )
}
