import { useDroppable } from '@dnd-kit/core'
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import { CheckCircledIcon, CircleIcon, CrossCircledIcon, Half2Icon } from '@radix-ui/react-icons'
import { BoardStatus } from '@schema'

import { cn } from '../../utils/cn'
import { ArchiveButton } from './ArchiveButton'
import { Card } from './Card'

export const StateCol = ({ state }: { state: BoardStatus }) => {
    const { setNodeRef } = useDroppable({
        id: state.id,
        data: {
            state,
        },
    })

    const issues = state.issues

    return (
        <div className="group mr-8 flex w-72 flex-1 flex-col rounded-md border-muted-foreground">
            <div className="mb-4 flex items-center justify-between border-muted-foreground p-4 text-base text-muted-foreground">
                <div className="flex items-center justify-start space-x-2">
                    {state.state === 'IN_PROGRESS' && <Half2Icon className="text-yellow-400" />}
                    {state.state === 'DONE' && <CheckCircledIcon className="text-green-400" />}
                    {state.state === 'UNSTARTED' && <CircleIcon className="text-purple-400" />}
                    {state.state === 'BLOCKED' && <CrossCircledIcon className="text-red-400" />}
                    <h2>{state.name}</h2>
                    <p className="text-sm text-primary">{state.issues.length}</p>
                </div>
                {state.state === 'DONE' && <ArchiveButton />}
            </div>
            <SortableContext items={issues.map((issue) => issue.id)} strategy={rectSortingStrategy}>
                <div
                    ref={setNodeRef}
                    className={cn('flex flex-1 h-full flex-col items-start gap-4 px-1 pb-4')}
                >
                    {issues.map((issue) => (
                        <Card key={issue.id} issue={issue} isOverlay={false} />
                    ))}
                </div>
            </SortableContext>
        </div>
    )
}
